.header {
	padding-top: 100px;
	padding-bottom: 300px;
	background-image: url('media/header.png');
	background-size: cover;
	background-position: center;
	max-height: 600px;
}

.header h1 {
	text-align: center;
	font-weight: 600;
	font-size: 48px;
	color: white;
	margin-bottom: 100px;
}

.feader_form {
	text-align: center;
}

@media (max-width: 1450px) {
	.header {
		max-height: 450px;
	}
}

@media (max-width: 768px) {
	.header h1 {
		font-size: 30px;
	}
}

@media (max-width: 480px) {
	.header {
		max-height: 400px;
	}
	.header h1 {
		font-size: 30px;
		margin-bottom: 50px;
	}
}
