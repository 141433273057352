.form {
  display: flex;
  justify-content: center;
}

.form input {
  width: 450px;
  margin-right: 12px;
}

.input_block {
  display: flex;
  flex-direction: column;
}

.autocomplete {
  width: 80%;
  background: #ffff;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.autocompleteItems {
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 5px;
  cursor: pointer;
}

.autocompleteItems:hover {
  background: rgb(228, 226, 226);
}

@media (max-width: 1200px) {
  .form input {
    width: 400px;
  }
}

@media (max-width: 768px) {
  .form input {
    width: 250px;
  }
  .autocomplete {
    width: 94%;
  }
}

@media (max-width: 480px) {
  .form input {
    width: 200px;
  }
}
