.video {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 260px;

  .categories,
  .professions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 65px;
    text-align: center;
    padding: 8px 0;
    letter-spacing: 1px;
    font-size: 16px;
  }

  .professions {
    height: 35px;
    padding-bottom: 14px;
    font-weight: 500;
  }

  .title {
    display: flex;
    align-items: center;
    width: 240px;
    height: 75px;
    text-align: center;
    padding: 8px 0;
    letter-spacing: 1px;
    font-size: 17px;
  }
  .play_icon {
    position: absolute;
    z-index: 3;
    top: 180px;
    left: 115px;
    opacity: 0;
    svg {
      color: green;
    }
  }
  img {
    width: 260px;
  }
  &:hover .play_icon {
    opacity: 1;
  }
}
