.header_icons {
	margin-left: 15px;
	width: 30px;
	height: 30px;
	color: #796703;
}

.footer_icons {
	margin-right: 15px;
	width: 30px;
	height: 30px;
	// color: #0957cb;
	color: #0c5dd5;
}
