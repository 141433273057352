.footer {
	display: flex;
	justify-content: space-between;
	padding: 70px 0;
	font-weight: 300;
	font-size: 14px;
	color: #796703;
}


@media (max-width: 480px) {
	.footer {
		padding: 40px 12px;
	}
}
