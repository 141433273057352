.modal {
  position: fixed;
  pointer-events: none;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.48);

  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.modal_content {
  pointer-events: all;
  margin-top: -50px;
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close_icon {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  border: none;
  background: red;
  border-radius: 16px;
  color: white;
  width: 28px;
  height: 28px;
  top: -8px;
  right: -6px;
  font-size: 12px;
  transition: 0.25s;
}

.close_icon:hover {
  transform: scale(1.12);
}

@media (max-width: 480px) {
  .close_icon {
    top: 2px;
    right: 2px;
  }
}
