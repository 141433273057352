.main_content {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
  gap: 25px;
}

.menu_btn {
  display: none;
}

@media (max-width: 1200px) {
  .main_content {
    display: flex;
    gap: 25px;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .main_content {
    display: flex;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .main_content {
    display: grid;
    justify-content: center;
    margin-top: 50px;
  }
  .menu {
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 99;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(36, 28, 21, 0.7);
    color: white;
    transition: 0.5s;
    transform: translateX(-100%);
  }
  .menu.active {
    transform: translateX(0%);
  }
  .menu_list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 50%;
    flex-direction: column;
    overflow: scroll;
  }
  .menu_btn {
    display: block;
    font-size: 40px;
    color: rgba(36, 28, 21, 0.5);
    color: #0d6efd;
    position: absolute;
    right: -35px;
    top: center;
  }
  .menu.active .menu_btn {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    color: #0d6efd;
  }
}
