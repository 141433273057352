.input {
	height: 33px;
	padding-left: 16px;
	color: #68696b;
	border: 1px solid #d3dce6;
	border-radius: 6px;
	border: none;
	border-bottom: 4px solid transparent;
	font-weight: 400;
	font-size: 15px;
	outline: none;
}
