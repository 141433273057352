.checkbox_container h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.checkbox_container p {
  font-weight: 400;
  font-size: 16px;
}

.checkbox_container input {
  margin-right: 10px;
}

.button {
  display: flex;
  gap: 7px;
}
button {
  padding: 7px 12px;
}

.search_button {
  margin-top: 29px;
}

.checkbox_container .show_more {
  margin-top: 4px;
  font-size: 15px;
  cursor: pointer;
}

.links {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  letter-spacing: 0.6px;
  h3 {
    margin-bottom: 10px;
  }
  a {
    color: black;
    &:hover {
      color: rgb(49, 49, 49);
    }
  }
}

@media (max-width: 480px) {
  .links {
    a {
      color: white;
      &:hover {
        color: white;
      }
    }
  }
}
